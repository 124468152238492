document.addEventListener('DOMContentLoaded', function () {

    $('.acc-item .acc-item__title').on('click', function () {
        $(this).next().slideToggle(300);
        $(this).toggleClass('active');
    });


    let duration = 2;
    let xCount = 100;

    if (window.innerWidth < 991) {
        duration = 1;
        xCount = 50;
    }

    const initSmoothAppearance = (selector, animationOptions = {}) => {
        const defaultOptions = {
            opacity: 0,
            duration: duration,
            ease: 'linear',
        };

        const options = { ...defaultOptions, ...animationOptions };

        gsap.utils.toArray(selector).forEach((element, index) => {
            const staggerDelay = index * (animationOptions.stagger || 0);

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: element,
                    start: 'top 95%',
                    end: 'top 85%', // increase end value to avoid overlap
                    scrub: 2,
                },
            });

            tl.from(element, {
                delay: staggerDelay,
                ...options,
                x: options.x || 0,
                duration: options.duration || 1,
                transform: 'translateZ(0)',
                willChange: 'transform, opacity',
            });
        });
    };

    /* initSmoothAppearance('.advantages .item', { y: -xCount, stagger: 0.5 }); */

    initSmoothAppearance('.about-anim', { y: xCount, stagger: 0.5 });

    initSmoothAppearance('.text-bottom span:nth-child(1)', { x: xCount });
    initSmoothAppearance('.text-bottom span:nth-child(2)', { x: -xCount });

    initSmoothAppearance('.advant-num .item', { y: xCount, stagger: 0.5 });

    initSmoothAppearance('.price__title span:nth-child(1)', { x: -xCount });
    initSmoothAppearance('.price__title span:nth-child(2)', { x: xCount });

    initSmoothAppearance('.left div', { y: xCount, stagger: 0.5 });



    /* window.addEventListener("load", function () {
        var loader = document.getElementById("loader");
        loader.classList.add("hide");
    }); */

});